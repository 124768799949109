<template>
    <modal v-model:show="showStaffModal" modal-classes="modal-white" header-classes="align-items-center" body-classes="pt-0"
        size="lg" :show-close="showClose" lock-scroll>
        <div class="modal-header" style="position: sticky; top: 0;">
            <h6 class="modal-title text-capitalize text-default">
                {{ i18n.$t("staff.modal.sendMessage.header") }}
            </h6>
            <button type="button" class="close" @click="handleClose">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="filter-container">
                <div class="filter-group">
                    <h6>{{ i18n.$t("staff.modal.sendMessage.filter.project") }} <i class="fas fa-filter"></i></h6>
                    <div v-for="project in projects.value" :key="project.project_id">
                        <label>
                            <input type="checkbox" v-model="selectedProjects" :value="project.project_id"
                                @change="handleShifts(project)" />
                            {{ project.project_name }}
                        </label>
                    </div>
                </div>
                <div class="filter-group">
                    <h6>{{ i18n.$t("staff.modal.sendMessage.filter.shift") }} <i class="fas fa-filter"></i></h6>
                    <div v-for="shift in filteredShifts" :key="shift.shift_id">
                        <label>
                            <input type="checkbox" v-model="selectedShifts"
                                :value="{ shift_id: shift.shift_id, project_id: shift.project_id }" />
                            {{ shift.shift_name }}
                        </label>
                    </div>
                </div>
                <div class="filter-group">
                    <h6>{{ i18n.$t("staff.modal.sendMessage.filter.planingStatus") }} <i class="fas fa-filter"></i></h6>
                    <div v-for="status in planningStatus" :key="status.id">
                        <label>
                            <input type="checkbox"
                                @change="updateSelectedPlanningStatus(status.request_status, $event.target.checked)"
                                :checked="isPlanningStatusSelected(status.request_status)" />
                            {{ status.name }}
                        </label>
                    </div>
                </div>
                <div class="filter-group">
                    <h6>{{ i18n.$t("staff.modal.sendMessage.filter.skills") }} <i class="fas fa-filter"></i></h6>
                    <div v-for="skill in skills.value" :key="skill.skill_id">
                        <label>
                            <input type="checkbox" v-model="selectedSkills" :value="skill.skill_id" />
                            {{ skill.skill_name }}
                        </label>
                    </div>
                </div>

                <div class="filter-actions">
                    <button @click="clearFilters" class="btn btn-link">{{ i18n.$t("clearFilters") }}</button>
                </div>
            </div>

            <div class="row tab-content">
                <div class="col-12">
                    <div class="card shadow">
                        <div class="card-body">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="tabs-icons-text-1" role="tabpanel"
                                    aria-labelledby="tabs-icons-text-1-tab">
                                    <div class="row">
                                        <div v-bind:class="classObject">
                                            <Field class="form-control" style="border: none" name="search" type="text"
                                                label="search" placeholder="Search" v-model="search">
                                            </Field>

                                            <el-table class="table-responsive table-flush rounded" ref="userTable"
                                                header-row-class-name="thead-light" :row-class-name="tableRowClassName"
                                                :show-header="false" :current-row-key="1"
                                                :data="filteredUsers.filter((data) => !search || data.first_name.toLowerCase().includes(search.toLowerCase()) || data.last_name.toLowerCase().includes(search.toLowerCase()))"
                                                highlight-current-row @selection-change="handleSelectionChange">
                                                <el-table-column min-width="50px" prop="icon">
                                                    <template style="cursor: default;" v-slot="{ row }">
                                                        <div class="media align-items-center" style="cursor: default;">
                                                            <div>
                                                                <span
                                                                    class="avatar avatar-sm bg-success color-grey rounded-circle"
                                                                    translate="no">
                                                                    {{ getInitialsName(row.first_name, row.last_name) }}
                                                                </span>
                                                            </div>
                                                            <div style="margin-left: 15px; width: 100%;">
                                                                <div class="media-body font-weight-600 d-flex align-items-center">
                                                                    {{ row.first_name }} {{ row.last_name }}
                                                                    <i v-if="!row.hasDevice" 
                                                                    class="fas fa-exclamation-triangle ml-2"
                                                                    :title="i18n.$t('message.noDeviceToken')">
                                                                </i>
                                                                </div>
                                                                

                                                            </div>
                                                        </div>
                                                    </template>
                                                </el-table-column>

                                                <el-table-column width="100px">
                                                    <template v-slot="{ row }">
                                                        <base-checkbox v-model="row.isSelected" :disabled="row.isDisable"
                                                            @change="check(row)" class="mb-3">
                                                        </base-checkbox>
                                                    </template>
                                                </el-table-column>
                                            </el-table>

                                            <div id="block_container">
                                                <div id="bloc1">
                                                    <label style="color: #8898aa">
                                                        {{
                                                            i18n.$t("projectDetails.shiftRequest.selectAll")
                                                        }}
                                                    </label>
                                                </div>
                                                <div id="bloc2">
                                                    <base-checkbox v-model="selectAllChecked" @change="selectUnSelectAll"
                                                        class="mb-3">
                                                    </base-checkbox>
                                                </div>
                                            </div>


                                        </div>
                                        <div v-if="profileData" class="col-6">
                                            <div class="row">
                                                <div class="col-xl-12 order-xl-2">
                                                    <user-profile-view :profile="profileData">
                                                    </user-profile-view>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal-footer" style="position: sticky; bottom: 0; background-color: #FCFCFD; z-index: 1055;">
            <div class="d-flex align-items-center justify-content-between w-100">
                <span>{{ this.selectedUsers.length }} von {{ staffs.length }} Mitarbeiter ausgewählt</span>
                <div>
                    <base-button type="warning" class="text-white p-2 px-3" @click="handleContinue">
                        {{ i18n.$t("staff.modal.sendMessage.continue") }}
                    </base-button>
                    <base-button type="" class="ml-auto text-default p-2 px-3" @click="handleClose">
                        {{ i18n.$t("staff.modal.sendMessage.cancel") }}
                    </base-button>
                </div>
            </div>
        </div>
    </modal>
    <modal v-model:show="showMessageModal" modal-classes="modal-white" header-classes="align-items-center"
        body-classes="pt-0" size="lg" :show-close="showClose" lock-scroll>
        <div class="modal-header" style="position: sticky; top: 0;">
            <h6 class="modal-title text-capitalize text-default">
                {{ i18n.$t("staff.modal.sendMessage.header") }}
            </h6>
            <button type="button" class="close" @click="closeMessageModal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <span>{{ this.selectedUsers.length }} von {{ staffs.length }} Mitarbeiter ausgewählt</span>
            <form @submit.prevent="sendMessage">
                <div class="form-group">
                    <label for="message">{{ i18n.$t("staff.modal.sendMessage.title") }}</label>
                    <textarea v-model="title" class="form-control" id="title" rows="1" :maxlength=titleMaxLength
                        required></textarea>
                    <small>{{ titleChars }} / {{ titleMaxLength }}</small>
                </div>
                <div class="form-group">
                    <label for="message">{{ i18n.$t("staff.modal.sendMessage.message") }}</label>
                    <textarea v-model="message" class="form-control" id="message" rows="3" :maxlength=messageMaxLength
                        required></textarea>
                    <small>{{ messageChars }} / {{ messageMaxLength }}</small>
                </div>
                <div>
                    <base-button type="warning" class="text-white p-2 px-3" @click="sendMessage">
                        {{ i18n.$t("staff.modal.sendMessage.send") }}
                    </base-button>
                    <base-button type="" class="ml-auto text-default p-2 px-3" @click="closeMessageModal">
                        {{ i18n.$t("staff.modal.sendMessage.back") }}
                    </base-button>
                </div>
            </form>
        </div>
    </modal>
</template>
  
<script>
import Modal from "@/components/Modal";
import { useI18n } from "@/util/i18n";
import { onMounted, reactive, ref } from "vue";
import { useToast } from "vue-toastification";
import { COMPANYPROJECTAPI, GETPRJECTBYUSERAPI } from "@/services/private/Project";
import { GETPROJECTSHIFTSAPI, GETALLSHIFTREQUESTAPI, GETALLSHIFTMEMBERAPI } from "@/services/private/ProjectShift";
import { GETSKILLIDBYCOMPANYIDAPI, GETUSERAPI } from "../../services/private/Skills";
import { COMPANYUSERAPI } from "@/services/private/Company";
import { GETUSERINFOAPI } from "@/services/private/Profile";
import {GETUSERDEVICEAPI} from "@/services/private/UserDevice";
import storage from "@/util/storage";
import initialsName from "@/util/initialsName";
import { Field, Form } from "vee-validate";
import {
    ElTable,
    ElTableColumn,
    ElDropdownMenu,
    ElDropdownItem,
    ElDropdown,
} from "element-plus";
import { SENDPUSHNOTIFICATIONAPI } from "../../services/private/PushNotification";

export default {
    name: "filter-modal",
    props: {
        showModal: Boolean,
    },
    components: {
        Modal,
        Field,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        [ElDropdown.name]: ElDropdown,
        [ElDropdownItem.name]: ElDropdownItem,
        [ElDropdownMenu.name]: ElDropdownMenu,
    },
    data() {
        return {
            selectedProjects: [],
            selectedShifts: [],
            selectedPlanningStatus: [],
            selectedSkills: [],
            search: "",
            users: [],
            profileData: null,
            showStaffModal: this.showModal,
            showMessageModal: false,
            title: "",
            message: "",
            titleMaxLength: 30,
            messageMaxLength: 120,
            toast: useToast(),
            i18n: useI18n(),
            selectAllChecked: true

        };
    },
    watch: {
        showModal(val) {
            this.showStaffModal = val;
        },
    },
    computed: {
        titleChars() {
            return this.title.length;
        },
        messageChars() {
            return this.message.length;
        },
        show() {
            return this.showModal;
        },
        showClose() {
            return true;
        },
        filteredUsers() {
            let filteredUsers = [...this.staffs];

            if (this.selectedProjects.length > 0) {
                filteredUsers = filteredUsers.filter(user =>
                    this.selectedProjects.some(projectId =>
                        user.projects.includes(projectId)
                    )
                );
            }

            if (this.selectedShifts.length > 0) {
                filteredUsers = filteredUsers.filter(user =>
                    this.selectedShifts.some(shiftId =>
                        user.shifts.some(shift => shift.shift_id === shiftId.shift_id)
                    )
                );
            }

            if (this.selectedSkills.length > 0) {
                filteredUsers = filteredUsers.filter(user =>
                    this.selectedSkills.some(skillId =>
                        user.skills.includes(skillId)
                    )
                );
            }

            if (this.selectedPlanningStatus.length > 0) {
                filteredUsers = filteredUsers.filter(user =>
                    user.shifts.some(shift =>
                        this.selectedPlanningStatus.includes(shift.request_status)
                    )
                );
            }

            return filteredUsers;
        },
        selectedUsers() {
            return this.filteredUsers.filter(user => user.isSelected);
        },
        classObject() {
            if (this.profileData) {
                return "col-6";
            } else {
                return "col-12";
            }
        },
        showShifts() {
            return selectedProjects.includes(shift.project_id);
        },
        filteredShifts() {
            if (this.selectedProjects.length === 0) {
                return [];
            }
            return this.shifts.filter(shift =>
                this.selectedProjects.includes(shift.project_id)
            );
        },

    },
    methods: {
        updateSelectedPlanningStatus(statusArray, isChecked) {
            if (isChecked) {
                this.selectedPlanningStatus.push(...statusArray.filter(status => !this.selectedPlanningStatus.includes(status)));
            } else {
                this.selectedPlanningStatus = this.selectedPlanningStatus.filter(status => !statusArray.includes(status));
            }
        },
        isPlanningStatusSelected(statusArray) {
            return statusArray.every(status => this.selectedPlanningStatus.includes(status));
        },
        handleCurrentChange(val) {
            //this.currentRow = val;
           // this.loadUserProfile(val.user_id, val.version_no);
        },
        async loadUserProfile(userId, version) {
            //const user = this.userId;
            const data = {
                version_no: version,
                user_id: userId,
            };
            const result = await GETUSERINFOAPI(data);
            if (result && result.status === 200) {
                const { data } = result.data;
                this.profileData = data[0];
            }
        },
        handleClose() {
            this.clearFilters();
            this.clearMesagges();
            this.$emit("close");
        },
        handleContinue() {
            this.openMessageModal();
        },
        clearFilters() {
            this.selectedProjects = [];
            this.selectedShifts = [];
            this.selectedPlanningStatus = [];
            this.selectedSkills = [];
        },
        clearMesagges() {
            this.title = "";
            this.message = "";
        },
        tableRowClassName({ row }) {
            if (row.isSelected) {
                return "currentRow-background";
            } else {
                return "";
            }
        },
        getInitialsName(firstName, lastName) {
            return initialsName(`${firstName} ${lastName}`);
        },
        handleSelectionChange(val) {
            //console.log(val);
            //this.loadUserProfile(userId,Version);
        },
        selectUnSelectAll() {
        const isChecked = this.selectAllChecked;
        this.filteredUsers.forEach(user => {
            if (!user.isDisable) {
                user.isSelected = isChecked;
                this.check(user);
            }
        });
    },
    check(val) {
        if (val.isSelected && !val.isDisable) {
            if (!this.users.includes(val)) {
                this.users.push(val);
            }
        } else {
            const index = this.users.indexOf(val);
            if (index > -1) {
                this.users.splice(index, 1);
            }
        }
        this.selectAllChecked = this.filteredUsers.every(user => user.isSelected && !user.isDisable);
    },
        openMessageModal() {
            this.showStaffModal = false;
            this.showMessageModal = true;
        },
        closeMessageModal() {
            this.showMessageModal = false;
            this.$nextTick(() => {
                this.showStaffModal = true;
            })
            
        },
        async sendMessage() {
            if (!this.title.trim()) {
                this.toast.error(this.i18n.$t("staff.modal.sendMessage.titleRequired"));
                return;
            }

            if (this.selectedUsers.length === 0) {
                this.toast.error(this.i18n.$t("staff.modal.sendMessage.noUsersSelected"));
                return;
            }
            console.log(this.title, this.message);
            console.log(this.selectedUsers);
            for (let i = 0; i < this.selectedUsers.length; i++) {
                const data = {
                    user_id: this.selectedUsers[i].user_id,
                    title: this.title,
                    body: this.message,
                };
                console.log(data);
                const result = await SENDPUSHNOTIFICATIONAPI(data);
                if (result && result.status === 200) {
                    this.toast.success(this.i18n.$t("staff.modal.sendMessage.success"));
                } else {
                    this.toast.error(this.i18n.$t("staff.modal.sendMessage.failed"));
                }
            }

            this.closeMessageModal();
            this.$emit("close");
            this.clearFilters();
            this.clearMesagges();
        },
        handleShifts(project) {
            const projectId = project.project_id;
            if (!this.selectedProjects.includes(projectId)) {
                this.selectedShifts = this.selectedShifts.filter(shiftId => {
                    const shift = this.shifts.find(s => s.shift_id === shiftId.shift_id);
                    return shift && shift.project_id !== projectId;
                });
            }
        },
    },
    setup(props, { emit }) {
        const i18n = useI18n();
        let projects = reactive([]);
        let skills = reactive([]);
        let shifts = ref([]);
        let staffs = ref([]);
        let planningStatus = ref([
            { id: 1, name: "Angefragt", request_status: ["user_requested"] },
            { id: 2, name: "Verfügbar", request_status: ["user_available"] },
            { id: 3, name: "Vielleicht", request_status: ["user_tentative", "user_perhaps"] },
            { id: 4, name: "Abgesagt", request_status: ["user_unavailable"] },
            { id: 5, name: "Eingeplant", request_status: ["user_added"] },
        ]);
        let userDevices = ref([]);


        async function getProjects(flag) {
            const user = storage().getUser();
            const role = user && user.role;
            if (role && (role.role_code === "A" || role.role_code === "D")) {
                const data = { flag };
                const result = await COMPANYPROJECTAPI(data);
                if (result && result.status === 200) {
                    const { data } = result.data;
                    projects.value = data.map((p) => {
                        return {
                            project_id: p.project_id,
                            project_name: p.project_name,
                        };
                    });
                }
            }
        }


        async function getSkills() {
            const user = storage().getUser();
            const company_id = user.company_id;
            const result = await GETSKILLIDBYCOMPANYIDAPI(company_id);
            if (result && result.status === 200) {
                const { data } = result.data;
                skills.value = data.map((s) => {
                    return {
                        skill_id: s.skill_id,
                        skill_name: s.skill_name,
                    }
                });
            }
        }

        async function getShifts() {
            const user = storage().getUser();
            const role = user && user.role;
            if (role && (role.role_code === "A" || role.role_code === "D")) {
                for (let i = 0; i < projects.value.length; i++) {
                    const result = await GETPROJECTSHIFTSAPI({ project_id: projects.value[i].project_id, flag: false });
                    if (result && result.status === 200) {
                        const { data } = result.data;
                        const newShifts = data.map((p) => {
                            return {
                                shift_id: p.shift_id,
                                shift_name: p.shift_name,
                                project_id: p.project_id,
                            };
                        });
                        shifts.value.push(...newShifts);
                    }
                }
            }
        }

        async function getCompanyStaff(state) {
            const result = await COMPANYUSERAPI({ is_active: state });
            if (result && result.status === 200) {
                staffs.value = result.data.data.map((r) => {
                    const hasDevice = userDevices.value.some((m) => m.first_name === r.first_name && m.last_name === r.last_name);
                    return {
                        company_id: r.company_id,
                        email: r.email,
                        first_name: r.first_name,
                        is_active: r.role.is_active,
                        last_name: r.last_name,
                        role_name: r.role.role_name,
                        user_id: r.user_id,
                        role_code: r.role.role_code,
                        version_no: r.version_no,
                        projects: [],
                        shifts: [],
                        skills: [],
                        hasDevice: hasDevice ,
                        isSelected: hasDevice ,
                        isDisable: !hasDevice 


                    };
                });
                await getUserAssociations();
                await getUserSkills();

                
            }
        }

        async function getUserAssociations() {
            for (let i = 0; i < shifts.value.length; i++) {
                const result = await GETALLSHIFTREQUESTAPI({ shift_id: shifts.value[i].shift_id, flag: "false" });
                if (result && result.status === 200) {
                    const { data } = result.data;

                    const userLists = [
                        ...data[0].requestedUserList,
                        ...data[0].availableUserList,
                        ...data[0].scheduleUserList,
                    ];

                    userLists.forEach(user => {
                        let staffMember = staffs.value.find(s => s.user_id === user.user_id);
                        if (staffMember) {
                            if (!staffMember.shifts.includes(user.shift_id)) {
                                staffMember.shifts.push({
                                    shift_id: user.shift_id,
                                    request_status: user.shift_request_status
                                });
                            }
                            if (!staffMember.projects.includes(shifts.value[i].project_id)) {
                                staffMember.projects.push(shifts.value[i].project_id);
                            }
                        }
                    });
                }
            }
        }

        async function getUserSkills() {
            for (let i = 0; i < skills.value.length; i++) {
                const result = await GETUSERAPI(skills.value[i].skill_id);
                if (result && result.status === 200) {
                    const { data } = result.data;
                    data.forEach(user => {
                        let staffMember = staffs.value.find(s => s.user_id === user.user_id);
                        if (staffMember && !staffMember.skills.includes(skills.value[i].skill_id)) {
                            staffMember.skills.push(skills.value[i].skill_id);
                        }
                    });
                }
            }
        }

        async function getUserDevice(params) {
            console.log("SendNotificationSelectStaffModal, getUserDevice");
            
            const result = await GETUSERDEVICEAPI();
            if(result && result.status === 200) {
                console.log(result.data);
                const {data} = result.data
                userDevices.value = data.map((r) => {
                    return {
                        first_name: r.first_name,
                        last_name: r.last_name
                    }
                })
                
            }
            console.log("End of SendNotificationSelectStaffModal, getUserDevice");
            console.log(userDevices);
        }


        onMounted(async () => {
            await getUserDevice();
            await getProjects('CURRENT');
            await getSkills();
            await getShifts();
            await getCompanyStaff(true);

        });


        return {
            i18n,
            projects,
            skills,
            shifts,
            staffs,
            planningStatus,
            userDevices
        };
    },
};
</script>
  
<style>
.filter-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filter-group {
    flex: 1;
    margin-right: 10px;
}

.filter-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.search-bar {
    margin-bottom: 20px;
}

.user-list {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.user-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.user-item img.avatar {
    border-radius: 50%;
    margin-right: 10px;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.form-group textarea {
    font-size: 14px;
    padding: 5px;
}

.form-group small {
    font-size: 12px;
    display: block;
    margin-top: 2px;
}

.filter-group label {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
}

.filter-group input[type="checkbox"] {
    margin-right: 5px;
}


.filter-actions button {
    font-size: 14px;
    padding: 5px 10px;
    
}</style>
  