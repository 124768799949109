<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-default navbar-dark': type === 'default' }"
  >
    <!-- Search form -->
    <form
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      id="navbar-search-main"
    >
      <!-- <div class="form-group mb-0">
        <div class="input-group input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input
            class="form-control"
            :placeholder="i18n.$t('nav.search')"
            type="text"
          />
        </div>
      </div> -->
      <button
        type="button"
        class="close"
        data-action="search-close"
        data-target="#navbar-search-main"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </form>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-1 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li class="nav-item d-none">
        <div
          class="nav-link"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in"></i>
        </div>
      </li>
      <base-dropdown
        class="nav-item"
        tag="li"
        title-classes="nav-link position-relative"
        title-tag="a"
        menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
        icon="ni ni-bell-55"
        :dot="is_notified"
        @click="toggleNotification"
      >
        <!-- Dropdown header -->
        <div
          class="px-3 py-3 d-flex align-items-center justify-content-between"
        >
          <h6 class="text-sm text-muted m-0">
            {{ i18n.$t("notification.firstText") }}
            <strong class="text-primary">
              {{ total_unread_notification || 0 }}
            </strong>
            {{ i18n.$t("notification.secondText") }}
          </h6>
        </div>
        <!-- List group -->
        <div class="list-group list-group-flush scrollable">
          <span
            class="list-group-item list-group-item-action"
            v-for="(notify, index) in notifications"
            :key="index"
          >
            <div
              class="row align-items-center"
              @click="updateReadNotification(notify)"
            >
              <div class="col-auto">
                <!-- Avatar -->
                <!-- <img
                  alt="Image placeholder"
                  src="img/theme/team-1.jpg"
                  class="avatar rounded-circle"
                /> -->
                <span class="avatar rounded-circle">
                  <div class="dot-2" v-if="notify.is_read === false"></div>
                  {{ getInitials(notify.notification_title) }}
                </span>
              </div>
              <div class="col ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm font-weight-bolder text-capitalize">
                      {{
                        notify.notification_title
                          ? notify.notification_title
                          : fullName
                      }}
                    </h4>
                  </div>
                  <div class="text-right text-muted">
                    <small>{{ fromNow(notify.created_dt) }}</small>
                  </div>
                </div>
                <p class="text-sm-2 mb-0">
                  <span v-html="notify.notification_desc"></span>
                </p>
              </div>
            </div>
          </span>
        </div>
        <!-- View all -->
        <span
          class="
            dropdown-item
            text-center text-default
            font-weight-bolder
            py-3
            border-top
          "
          v-if="notifications && notifications.length > 0"
          @click="clearAllNotifications"
        >
          {{ i18n.$t("nav.readAll") }}
        </span>
      </base-dropdown>
    </ul>
    <ul class="navbar-nav align-items-center ml-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0 pl-0" @click.prevent>
            <div class="media align-items-center">
              <span translate="no" class="avatar avatar-sm rounded-circle">
                <!-- <img alt="Image placeholder" src="img/theme/team-4.jpg" /> -->
                {{ setInitialsName }}
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold text-capitalize">
                  {{ fullName }}
                </span>
              </div>
            </div>
          </a>
        </template>

        <a href="#/pages/user" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>{{ i18n.$t("nav.profile") }}</span>
        </a>
        <a @click="showCompany()" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>Mein Arbeitgeber</span>
        </a>
        <!-- <a href="#!" class="dropdown-item">
          <i class="ni ni-settings-gear-65"></i>
          <span>{{ i18n.$t("nav.settings") }}</span>
        </a> -->
        <a href="#" class="dropdown-item" @click.prevent="toggleResetPassModal">
          <i class="ni ni-key-25"></i>
          <span>{{ i18n.$t("nav.password") }}</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" @click.prevent="toggleLogoutModal">
          <i class="ni ni-user-run"></i>
          <span>{{ i18n.$t("nav.logout") }}</span>
        </a>
      </base-dropdown>
    </ul>
    <modal
      v-model:show="models.logoutModalVisible"
      modal-classes="modal-warning"
      gradient="warning"
      header-classes="align-items-center"
    >
      <template v-slot:header>
        <h6 class="modal-title text-white">{{ i18n.$t("logout.header") }}</h6>
      </template>
      <div class="text-center">
        <h1 class="text-3xl text-white">
          <i class="ni ni-button-power"></i>
        </h1>
        <h2 class="text-white font-weight-bold">
          {{ i18n.$t("logout.subHeader") }}
        </h2>
        <p class="text-white mb-0">
          {{ i18n.$t("logout.smallText") }}
        </p>
      </div>
      <template v-slot:footer>
        <base-button type="secondary" @click="logout">
          <div class="d-flex align-items-center">
            <spinner color="text-default" v-show="loader" />
            <span>{{ i18n.$t("logout.button.logout") }}</span>
          </div>
        </base-button>
        <base-button
          type="link"
          class="ml-auto text-white"
          @click="models.logoutModalVisible = false"
        >
          {{ i18n.$t("logout.button.cancel") }}
        </base-button>
      </template>
    </modal>
    <modal
      v-model:show="models.resetPassModalVisible"
      modal-classes="modal-warning"
      gradient="warning"
      header-classes="align-items-center"
    >
      <template v-slot:header>
        <h6 class="modal-title text-white">{{ i18n.$t("logout.header") }}</h6>
      </template>
      <div class="text-center">
        <h2 class="text-white font-weight-bold">
          {{ i18n.$t("logout.subHeader") }}
        </h2>
        <p class="text-white mb-0">
          {{ i18n.$t("logout.smallText") }}
        </p>
      </div>
      <template v-slot:footer>
        <base-button
          native-type="button"
          type="secondary"
          @click.stop="sendResetEmail"
        >
          <div class="d-flex align-items-center">
            <spinner color="text-default" v-show="loader" />
            <span>{{ i18n.$t("resetPassword.form.button") }}</span>
          </div>
        </base-button>
        <base-button
          type="link"
          class="ml-auto text-white"
          @click="models.resetPassModalVisible = false"
        >
          {{ i18n.$t("logout.button.cancel") }}
        </base-button>
      </template>
    </modal>
  </base-nav>
</template>
<script>
import { useToast } from "vue-toastification";
import BaseNav from "@/components/Navbar/BaseNav";
import Modal from "@/components/Modal";
import { FORGOTPASSWORDAPI, LOGOUTAPI } from "@/services/public/Auth";
import {
  GETNOTOFICATIONAPI,
  CLEARALLNOTIFICATIONAPI,
  READONENOTIFICATIONAPI,
  CLICKNOTIFICATIONBELLAPI,
} from "@/services/private/Notification";
import { useI18n } from "@/util/i18n";
import date from "@/util/date";
import initialsName from "@/util/initialsName";
import storage from "@/util/storage";
import { CLEARNOTIFICATIONBYIDAPI } from "../../services/private/Notification";

export default {
  components: {
    BaseNav,
    Modal,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    fullName() {
      return `${this.$store.getters.fullName}`;
    },
    setInitialsName() {
      return `${this.$store.getters.initialsName}`;
    },
    isRoleAdminOrDispatch() {
      const user = this.$store.state.user; //storage().getUser();
      const role = user && user.role;
      return role && (role.role_code === "A" || role.role_code === "D");
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      models: {
        logoutModalVisible: false,
        resetPassModalVisible: false,
        resetPassSuccessModalVisible: false,
      },
      loader: false,
      notifications: [],
      is_notified: false,
      total_unread_notification: 0,
    };
  },
  setup() {
    const i18n = useI18n();
    const toast = useToast();
    return { i18n, toast };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleLogoutModal() {
      this.models.logoutModalVisible = !this.models.logoutModalVisible;
    },
    toggleResetPassModal() {
      this.models.resetPassModalVisible = !this.models.resetPassModalVisible;
    },
    async sendResetEmail() {
      this.loader = true;
      const email = this.$store.state.user.email;
      const data = {
        email,
      };
      const result = await FORGOTPASSWORDAPI(data);
      this.loader = false;
      if (result && result.status === 200) {
        this.models.resetPassModalVisible = false;
        setTimeout(() => {
          this.logout();
        }, 500);
      }
    },
    showCompany() {
      const companyId = this.$store.state.user.company_id;
      if (companyId) {
        this.$router.push({
          name: "CompanyProfile",
          params: { company_id: companyId },
        });
      }
    },
    async getNotification() {
      const result = await GETNOTOFICATIONAPI();
      if (
        result &&
        result.status === 200 &&
        result.data &&
        result.data.data &&
        Array.isArray(result.data.data)
      ) {
        this.notifications = result.data.data[0].notifications;
        this.is_notified = result.data.data[0].is_notified;
        this.total_unread_notification =
          result.data.data[0].notifications.reduce(
            (counter, obj) =>
              obj.is_read === false ? (counter += 1) : counter,
            0
          );
      }
    },
    async clearAllNotifications() {
      const user = storage().getUser();
      const data = {
        user_id: user.user_id,
      };
      await CLEARALLNOTIFICATIONAPI(data);
      await this.getNotification();
    },
    async clearNotificationById(notify) {
      await CLEARNOTIFICATIONBYIDAPI({ notification_id: notitfy.notification_id });
      await this.getNotification();
    },
    async updateReadNotification(notify) {
      await READONENOTIFICATIONAPI({ notification_id: notify.notification_id });
      await this.getNotification();
      if (notify.redirect_url !== null) {
        if (
          notify.redirect_url.indexOf("project/") !== -1 ||
          notify.redirect_url.indexOf("shift/") !== -1
        ) {
          notify.redirect_url =
            notify.redirect_url + "/" + this.randomIntFromInterval(1, 100);
        }
        this.$router.push(notify.redirect_url);
      }
    },

    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    async toggleNotification() {
      const user = storage().getUser();
      await CLICKNOTIFICATIONBELLAPI({
        user_id: user.user_id,
        is_notified: false,
      });
      this.is_notified = false;
      await this.getNotification();
    },
    getInitials(name) {
      return initialsName(name);
    },
    fromNow(payload) {
      return date().fromNow(payload);
    },
    async logout() {
      this.loader = true;
      const user = storage().getUser();
      await LOGOUTAPI({ user_id: user && user.user_id });
      this.loader = false;
      this.models.logoutModalVisible = false;
      storage().clear();
      this.$store.commit("logout");
      window.location = "/#/login";
    },
  },
  mounted() {
    this.getNotification();
    setInterval(() => {
      const user = storage().getUser();
      if (user && user.user_id) {
        this.getNotification();
      }
    }, 60 * 1000);
  },
};
</script>
